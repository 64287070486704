// app/providers.tsx
"use client";
import posthog from "posthog-js";
import { usePostHog } from "posthog-js/react";
import { createClient } from "./lib/supabase/client";
import { PostHogProvider } from "posthog-js/react";
import { useState, useEffect } from "react";

const client = createClient();

if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: "https://d2c1x4igygc37y.cloudfront.net", // Remove hard coded URL
    ui_host: "https://us.posthog.com",
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true,
      },
    },
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
  });
}

export function PHProvider({ children }: { children: React.ReactNode }) {
  const posthog = usePostHog();

  useEffect(() => {
    const getSession = async () => {
      const {
        data: { session },
      } = await client.auth.getSession();
      if (session) {
        posthog?.identify(session.user.id, {
          email: session.user.email,
        });
      }
    };

    getSession();
  }, [posthog]);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
