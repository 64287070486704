import(/* webpackMode: "eager" */ "/Users/mustafaabdelhamid/Documents/git/ValuCents/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/Users/mustafaabdelhamid/Documents/git/ValuCents/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/Users/mustafaabdelhamid/Documents/git/ValuCents/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/Users/mustafaabdelhamid/Documents/git/ValuCents/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/Users/mustafaabdelhamid/Documents/git/ValuCents/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/Users/mustafaabdelhamid/Documents/git/ValuCents/src/app/components/Crisp.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/mustafaabdelhamid/Documents/git/ValuCents/src/app/components/NavBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/Users/mustafaabdelhamid/Documents/git/ValuCents/src/app/contexts/AuthContext.tsx");
;
import(/* webpackMode: "eager" */ "/Users/mustafaabdelhamid/Documents/git/ValuCents/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/Users/mustafaabdelhamid/Documents/git/ValuCents/src/app/PostHogPageView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PHProvider"] */ "/Users/mustafaabdelhamid/Documents/git/ValuCents/src/app/providers.tsx");
