"use client";

import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { createClient } from "../lib/supabase/client";
import { useAuth } from "../contexts/AuthContext";

import account_avatar from "../../../public/circle-user_9821492.svg";
import logo from "../../../public/logo-with-background.svg";

const NavBar = () => {
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);

  const { user, loading, setUser } = useAuth();
  const client = createClient();
  const router = useRouter();

  const handleHamburgerMenu = () => {
    setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
  };

  const handleAccountMenu = () => {
    setIsAccountMenuOpen(!isAccountMenuOpen);
  };

  const handleSignOut = async () => {
    const { error } = await client.auth.signOut();
    if (error) {
      console.error("Logout Error:", error);
    }
    setUser(null);
    router.push("/");
  };

  return (
    <div className="navbar bg-base-100">
      <div className="flex-1">
        {/* <Link href="/" className="w-64 h-16 relative overflow-hidden"> */}
        <Link href="/" className="btn btn-ghost normal-case text-xl">
          ValuCents
          {/* <Image
            src={logo}
            // width={256}
            // height={64}
            alt="Logo for ValuCents"
            fill={true}
            objectFit="cover"
            style={{
              borderRadius: "0.25rem",
            }}
          /> */}
        </Link>
        <div className="menu menu-horizontal hidden lg:flex" id="mobile-menu">
          <ul className="flex flex-col lg:flex-row">
            {/* {userSession && ( */}
            <>
              <li>
                <Link href="/deals" className="font-normal">
                  Deals
                </Link>
              </li>
              <li>
                <Link href="/browse-coupons">Browse Coupons</Link>
              </li>
              <li>
                <Link href="/notify">Coupon Tracker</Link>
              </li>
            </>
            {/* <li>
              <Link href="/premium" onClick={handleHamburgerMenu}>
                Premium
              </Link>
            </li> */}
            <li>
              <Link href="/tutorial" onClick={handleHamburgerMenu}>
                Tutorial
              </Link>
            </li>
            <li>
              <Link href="/about-us" onClick={handleHamburgerMenu}>
                About Us
              </Link>
            </li>
            <li>
              <a
                href="https://t.me/+-XX9lzPghoYzY2Q5"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleHamburgerMenu}
              >
                Join our Telegram
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex-none gap-2">
        <div className="dropdown dropdown-end">
          <div
            tabIndex={0}
            role="button"
            className="btn btn-ghost lg:hidden"
            onClick={handleHamburgerMenu}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </div>
          {isHamburgerMenuOpen && (
            <ul
              tabIndex={0}
              className="mt-1 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-48"
            >
              <li>
                <Link href="/deals" onClick={handleHamburgerMenu}>
                  Deals
                </Link>
              </li>
              <li>
                <Link href="/browse-coupons" onClick={handleHamburgerMenu}>
                  Browse Coupons
                </Link>
              </li>
              <li>
                <Link href="/notify" onClick={handleHamburgerMenu}>
                  Coupon Tracker
                </Link>
              </li>
              {/* <li>
                <Link href="/premium" onClick={handleHamburgerMenu}>
                  Premium
                </Link>
              </li> */}
              <li>
                <Link href="/tutorial" onClick={handleHamburgerMenu}>
                  Tutorial
                </Link>
              </li>
              <li>
                <Link href="/about-us" onClick={handleHamburgerMenu}>
                  About Us
                </Link>
              </li>
              <li>
                <a
                  href="https://t.me/+-XX9lzPghoYzY2Q5"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleHamburgerMenu}
                >
                  Join Our Telegram
                </a>
              </li>
            </ul>
          )}
        </div>
        <div className="dropdown dropdown-end">
          <label
            tabIndex={0}
            className="btn btn-ghost btn-circle avatar"
            onClick={handleAccountMenu}
          >
            <div className="w-10 rounded-full">
              <Image src={account_avatar} alt="Account Management" />
            </div>
          </label>
          {isAccountMenuOpen && (
            <ul
              tabIndex={0}
              className="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52"
            >
              {loading ? (
                <li>
                  <span>Loading...</span>
                </li>
              ) : user ? (
                <>
                  {/* <li>
                    <a className="justify-between">
                      Profile
                      <span className="badge">New</span>
                    </a>
                  </li> */}
                  <li>
                    {/* <a>Settings</a> */}
                    <Link href="/settings" onClick={handleAccountMenu}>
                      Settings
                    </Link>
                  </li>
                  <li>
                    <a onClick={handleSignOut}>Logout</a>
                  </li>
                </>
              ) : (
                <li>
                  <Link href="/login">Login</Link>
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
